@import url('https://fonts.googleapis.com/css2?family=Shippori+Antique&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

/*
@value accent-color: rgb(0, 100, 255);
*/
@value accent-color: #ff0033;
@value black-color: #111;
@value white-color: #fff;

body {
  /* font-family: Helvetica, Arial, sans-serif; */
	/* font-family: 'Space Grotesk', Helvetica, Arial, sans-serif; */
  /* font-family: 'Shippori Antique', Helvetica, Arial, sans-serif; */
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Open Sans', 'Helvetica Neue', sans-serif; */
	font-family: BlinkMacSystemFont, Inter, 'Segoe UI', 'Helvetica Neue', sans-serif;
	/* font-size: 1vw; */
	font-size: 16px;
}

body, :global(#root) {
	min-height: 100vh;
}

body, .nav {
	color: black-color;
	background: white-color;
}

body {
	animation: fadeIn 200ms linear 1;
  -webkit-animation: fadeIn 200ms linear 1;
}

a { color: inherit;	}

.page {
	padding: 0 3em;
	overflow: hidden;
	border: 0.5em solid #f9f9f9;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	box-sizing: border-box;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

:global(.dark),
:global(.dark) .nav {
	color: white-color;
	background: black-color;
}

:global(.dark) .page {
	border-color: #1a1a1a;
}

h2 {
	font-weight:normal;
}

h3 {
  font-size: 0.95em;
	text-transform: uppercase;
	margin: 1.5em 0 0.75em;
	font-weight: bold;
}

h3 span {
	font-size: 0.8em;
	opacity: 0.5;
}

section {
	padding: 4em 0;
	flex: 1;
}

section h2 {
	font-size:2em;
	margin:0 0 0.75em;
}

section p {
	max-width:40em;
	line-height:1.4em;
}

nav {
  display: flex;
	margin: 1.5em -1.5em;
	user-select: none;
}

.nav.mobileNav {
	display: none;
	z-index: 100;
}

.nav.mobileNav .menuButton {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 5em;
	height: 5em;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	right: 0;
	user-select: none;
	cursor: pointer;
	background: inherit;
	z-index: 101;
}

.nav.mobileNav .menuButton:hover {
	/* background: red; */
}

.nav.mobileNav .menuButton .icon {
	font-size: 2.5em;
}

.nav.mobileNav nav {
	box-sizing: border-box;
	width: 100vw;
	min-height: 100vh;
	padding-bottom: 1em;
	align-items: center;
	font-size: 1.5em;
	background: inherit;
	border-bottom: 0.5em solid #f9f9f9;
}

:global(.dark) .nav.mobileNav nav {
	border-color: #1a1a1a;
}

nav a {
  padding: 0.5em 1.5em;
  text-decoration: none;
}

nav a.active,
nav a:hover {
  text-decoration: underline;
}

nav .active {
	color: accent-color;
	cursor: default;
}

nav .initials {
	font-weight: bold;
	text-decoration: none !important;
	display: inline-flex;
	align-items: center;
	pointer-events: all;
}

nav .initials.active {
	color: inherit;
}

nav a .icon {
	margin: -0.25em -1.25em -0.25em 0.25em;
	opacity: 0;
}

nav a:hover .icon {
	opacity: 1;
}

footer {
	padding: 3em 0;
	font-size: 0.9em;
	font-weight:bold;
	opacity: 0.5
}

footer a {
	opacity: 0.5;
	padding: 0 0.5em;
}

footer a:hover {
	opacity: 1;
}


/* Styles for screen size < 1024px */
@media only screen and (max-width: 1024px) {
	body {
		font-size: 13px;
	}
	.page {
		padding: 0 2em;
	}
}

@media only screen and (max-width: 800px) {
	body {
		font-size: 12px;
	}
	.page {
		padding: 0 1.75em;
		border: 0;
	}
	.nav:not(.mobileNav) {
		display: none;
	}
	.nav.mobileNav {
		display: flex;
	}
	nav {
		flex-direction: column;
	}
}
