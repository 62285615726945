@import url(https://fonts.googleapis.com/css2?family=Shippori+Antique&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
@value accent-color: rgb(0, 100, 255);
*/

body {
  /* font-family: Helvetica, Arial, sans-serif; */
	/* font-family: 'Space Grotesk', Helvetica, Arial, sans-serif; */
  /* font-family: 'Shippori Antique', Helvetica, Arial, sans-serif; */
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Open Sans', 'Helvetica Neue', sans-serif; */
	font-family: BlinkMacSystemFont, Inter, 'Segoe UI', 'Helvetica Neue', sans-serif;
	/* font-size: 1vw; */
	font-size: 16px;
}

body, #root {
	min-height: 100vh;
}

body, .App_nav__1_46_ {
	color: #111;
	background: #fff;
}

body {
	animation: App_fadeIn__fi7eh 200ms linear 1;
  -webkit-animation: App_fadeIn__fi7eh 200ms linear 1;
}

a { color: inherit;	}

.App_page__CKP-2 {
	padding: 0 3em;
	overflow: hidden;
	border: 0.5em solid #f9f9f9;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	box-sizing: border-box;
}

@keyframes App_fadeIn__fi7eh {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes App_fadeIn__fi7eh {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.dark,
.dark .App_nav__1_46_ {
	color: #fff;
	background: #111;
}

.dark .App_page__CKP-2 {
	border-color: #1a1a1a;
}

h2 {
	font-weight:normal;
}

h3 {
  font-size: 0.95em;
	text-transform: uppercase;
	margin: 1.5em 0 0.75em;
	font-weight: bold;
}

h3 span {
	font-size: 0.8em;
	opacity: 0.5;
}

section {
	padding: 4em 0;
	flex: 1 1;
}

section h2 {
	font-size:2em;
	margin:0 0 0.75em;
}

section p {
	max-width:40em;
	line-height:1.4em;
}

nav {
  display: flex;
	margin: 1.5em -1.5em;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.App_nav__1_46_.App_mobileNav__3Qgmz {
	display: none;
	z-index: 100;
}

.App_nav__1_46_.App_mobileNav__3Qgmz .App_menuButton__3F9-h {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 5em;
	height: 5em;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	right: 0;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	cursor: pointer;
	background: inherit;
	z-index: 101;
}

.App_nav__1_46_.App_mobileNav__3Qgmz .App_menuButton__3F9-h:hover {
	/* background: red; */
}

.App_nav__1_46_.App_mobileNav__3Qgmz .App_menuButton__3F9-h .App_icon__NYH35 {
	font-size: 2.5em;
}

.App_nav__1_46_.App_mobileNav__3Qgmz nav {
	box-sizing: border-box;
	width: 100vw;
	min-height: 100vh;
	padding-bottom: 1em;
	align-items: center;
	font-size: 1.5em;
	background: inherit;
	border-bottom: 0.5em solid #f9f9f9;
}

.dark .App_nav__1_46_.App_mobileNav__3Qgmz nav {
	border-color: #1a1a1a;
}

nav a {
  padding: 0.5em 1.5em;
  text-decoration: none;
}

nav a.App_active__eYtTS,
nav a:hover {
  text-decoration: underline;
}

nav .App_active__eYtTS {
	color: #ff0033;
	cursor: default;
}

nav .App_initials__2SgyU {
	font-weight: bold;
	text-decoration: none !important;
	display: inline-flex;
	align-items: center;
	pointer-events: all;
}

nav .App_initials__2SgyU.App_active__eYtTS {
	color: inherit;
}

nav a .App_icon__NYH35 {
	margin: -0.25em -1.25em -0.25em 0.25em;
	opacity: 0;
}

nav a:hover .App_icon__NYH35 {
	opacity: 1;
}

footer {
	padding: 3em 0;
	font-size: 0.9em;
	font-weight:bold;
	opacity: 0.5
}

footer a {
	opacity: 0.5;
	padding: 0 0.5em;
}

footer a:hover {
	opacity: 1;
}


/* Styles for screen size < 1024px */
@media only screen and (max-width: 1024px) {
	body {
		font-size: 13px;
	}
	.App_page__CKP-2 {
		padding: 0 2em;
	}
}

@media only screen and (max-width: 800px) {
	body {
		font-size: 12px;
	}
	.App_page__CKP-2 {
		padding: 0 1.75em;
		border: 0;
	}
	.App_nav__1_46_:not(.App_mobileNav__3Qgmz) {
		display: none;
	}
	.App_nav__1_46_.App_mobileNav__3Qgmz {
		display: flex;
	}
	nav {
		flex-direction: column;
	}
}

